<template>
  <div class="paysucess">
    <van-nav-bar
      title="支付成功"
      left-arrow
      @click-left="onClickLeft"
    />
    <main>
      <img class="sucesIcon" src="../assets//image/success.png" alt="">
      <p class="sucesText">恭喜您，支付成功！</p>
      <div class="box">
        <div class="boxdetail">
          <p class="title">收款金额</p>
          <p class="money">{{money}}元</p>
        </div>
        <div class="line"></div>
        <div class="boxdetail">
          <p class="text">收款方</p>
          <p class="text">多杰泰</p>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name:'',
  components: {

  },
  props: {

  },
  data() {
    return {
      money: '',
      ip: ''
    }
  },
  created() {
    this.money = this.$route.query.id
    this.ip = this.$route.query.ip
  },
  methods: {
    onClickLeft() {
      if (this.ip == 1) {
        this.$router.replace({
          path: '/examination',
          query: {
            id: 3
          }
        })
      } else {
        this.$router.replace({
          path: '/exercise',
          query: {
            id: 3
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.paysucess{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  main{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    .sucesIcon{
      width: 344px;
      height: 312px;
    }
    .sucesText{
      text-align: center;
      font-size: 24px;
      font-family: SourceHanSansSC-Medium, SourceHanSansSC;
      font-weight: 500;
      color: #333333;
      line-height: 35px;
    }
    .box{
      width: 351px;
      height: 96px;
      background: #FFFFFF;
      box-shadow: 0px 0px 4px 0px rgba(160, 160, 160, 0.4);
      border-radius: 4px;
      border: 1px dashed #A0A0A0;
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .boxdetail{
        width: 320px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title{
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 22px;
          padding-top: 11px;
        }
        .money{
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #E02020;
          line-height: 22px;
          padding-top: 11px;
        }
        .text{
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 22px;
          padding-top: 7px;
        }
      }
      .line{
        width: 320px;
        height: 1px;
        border-bottom: 1px solid #979797;
        margin-top: 11px;
      }
    }
  }
}
</style>
